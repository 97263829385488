.link {
  text-decoration: none;
  font-family: "Nutino";
}

.menuButton {
  width: 90%;
  display: flex;
  align-items: center;
  padding: 10px;
  margin-bottom: 10px;
  background-color: var(--primary-main);
  color: white;
  border-radius: 8px;
  cursor: pointer;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.menuButton:hover {
  background-color: var(--primary-white);
  color: var(--primary-main);
}

.active .menuButton {
  background-color: var(--primary-white);
  color: var(--primary-main);
}

.icon {
  margin-right: 10px;
}

.icon img {
  width: 20px;
  height: 20px;
}

.label {
  font-size: 16px;
}
