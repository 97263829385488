/* @import url("https://fonts.googleapis.com/css2?family=Playtime+With+Hot+Toddies&display=swap"); */

@font-face {
  font-family: "Nutino";
  src: url("./assets/fonts/Nutino/Nunito-Regular.ttf") format("truetype"),
    url("./assets/fonts/Nutino/Nunito-SemiBold.ttf") format("truetype"),
    url("./assets/fonts/Nutino/Nunito-Bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
/* 
-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", */

body {
  margin: 0;
  font-family: "Nutino", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
