/* Loader.module.css */
.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.7); /* Semi-transparent background */
  z-index: 9999; /* Ensure the loader is on top of other content */
}

.spinner {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* DashboardLayout.module.css */
.dashboard {
  display: flex;
}

.mainContent {
  flex-grow: 1;
  position: relative; /* Make sure the loader can be positioned absolutely */
}
