.container {
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  background: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  width: 320px;
}

.uploadButton {
  cursor: pointer;
  background-color: var(--primary-main);
  color: var(--primary-white);
  padding: 10px 20px;
  border: none;
  border-radius: 25px;
  font-size: 16px;
  margin-bottom: 1rem;
  transition: background-color 0.3s ease, color 0.3s ease,
    border-color 0.3s ease;
}

.uploadButton:hover {
  background-color: var(--primary-white);
  color: var(--primary-main);
  border-color: var(--primary-main);
}

.hiddenInput {
  display: none;
}

.audioContainer {
  width: 100%;
  text-align: center;
}

.audioPlayer {
  width: 100%;
}

.buttonGroup {
  display: flex;
  justify-content: center;
  margin-top: 8px;
}
