/* BookPreviewCard.module.css */

.card {
  background-color: var(--thrird-bg-color);
  border-radius: var(--layout-border-radius);
  padding: 20px;
  width: 250px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
  cursor: pointer;
  position: relative;
  color: var(--primary-dark);
}

.card:hover {
  transform: scale(1.05);
}

.link {
  text-decoration: none;
  color: var(--primary-dark);
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.imageContainer {
  text-align: center;
  margin-bottom: 15px;
}

.image {
  border-radius: var(--layout-border-radius);
  width: 100%;
  height: auto;
  object-fit: cover;
}

.details {
  width: 100%;
}

.detailItem {
  display: flex;
  margin-bottom: 10px;
}

.label {
  font-weight: bold;
  margin-right: 5px;
  color: var(--primary-text-color);
  min-width: 100px; /* Обеспечивает выравнивание меток */
}

.value {
  color: var(--secondary-text-color);
  flex: 1;
  word-break: break-word;
}

.title {
  font-size: 1.2rem;
  margin-bottom: 10px;
  color: var(--primary-text-color);
  text-align: center;
}

.author {
  font-size: 1rem;
  margin-bottom: 10px;
  color: var(--secondary-text-color);
  text-align: center;
}

.description {
  font-size: 0.9rem;
  margin-bottom: 10px;
  color: var(--primary-text-color);
}

.rating {
  font-size: 1rem;
  color: var(--primary-text-color);
}

.buttonPanel {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  gap: 10px;
}

.button {
  background-color: var(--primary-color);
  color: white;
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.button:hover {
  background-color: var(--primary-color-hover);
}

/* Responsive Adjustments */
@media (max-width: 600px) {
  .card {
    width: 180px;
    padding: 15px;
  }

  .imageContainer {
    margin-bottom: 10px;
  }

  .detailItem {
    margin-bottom: 8px;
  }

  .label {
    min-width: 80px;
    font-size: 0.9rem;
  }

  .value {
    font-size: 0.9rem;
  }

  .title {
    font-size: 1rem;
    margin-bottom: 8px;
  }

  .author {
    font-size: 0.9rem;
    margin-bottom: 8px;
  }

  .description {
    font-size: 0.8rem;
    margin-bottom: 8px;
  }

  .rating {
    font-size: 0.9rem;
  }

  .buttonPanel {
    gap: 8px;
  }

  .button {
    padding: 4px 8px;
    font-size: 0.8rem;
  }
}
