.button {
  padding: 10px 20px;
  border: none;
  border-radius: 25px;
  background-color: var(--primary-main);
  color: var(--primary-white);
  cursor: pointer;
  font-size: 16px;
  margin: 0 1rem;
  transition: background-color 0.3s ease, color 0.3s ease,
    border-color 0.3s ease;
}

.button:hover {
  background-color: var(--primary-white);
  color: var(--primary-main);
  border-color: var(--primary-main);
}

.warn {
  background-color: var(--secondary-orange);
  border: 1px solid var(--secondary-orange);
  transition: background-color 0.3s ease, border-color 0.3s ease;
}

.warn:hover {
  background-color: var(--primary-white);
  color: var(--secondary-orange);
  border-color: var(--secondary-orange);
}

@media (max-width: 768px) {
  .button {
    width: 80%;
    font-size: 14px;
  }
}
