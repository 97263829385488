.booksContainer {
  padding: 20px;
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
  opacity: 1;
  transform: translateY(0);
}

.booksContainer.hidden {
  opacity: 0;
  transform: translateY(20px);
}

.header {
  text-align: center;
  margin: 0;
  margin-bottom: 20px;
  font-size: 2rem;
  color: var(--primary-text-color);
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
  opacity: 1;
  transform: translateY(0);
}

.header.hidden {
  opacity: 0;
  transform: translateY(-20px);
}

.booksGrid {
  padding-top: 2rem;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  position: relative;
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
  opacity: 1;
  transform: translateY(0);
}

.booksGrid.hidden {
  opacity: 0;
  transform: translateY(20px);
}

.buttonsContainer {
  display: flex;
  justify-content: flex-end;
  /* Removed absolute positioning */
  /* position: absolute; */
  /* top: 4rem; */
  /* right: 2rem; */
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
  opacity: 1;
  transform: translateY(0);
  z-index: 100;
  margin-bottom: 20px; /* Added spacing below the buttons */
}

.buttonsContainer.hidden {
  opacity: 0;
  transform: translateY(-20px);
}

.pagesContainer {
  padding-top: 2rem;
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.pageItem {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
}

.pageTypeContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.pageTypeHeader {
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  text-transform: capitalize;
  color: var(--primary-text-color);
}

.pageTypeContent {
  display: flex;
  overflow-x: auto;
  gap: 10px;
  padding-bottom: 10px;
}

.pageTypeContent > * {
  flex-shrink: 0;
}

.pageTypeContent::-webkit-scrollbar {
  height: 8px;
}

.pageTypeContent::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar-thumb-color);
  border-radius: 4px;
}

.pageTypeContent::-webkit-scrollbar-track {
  background-color: var(--scrollbar-track-color);
}

.miniButtonsContainer {
  display: flex;
}
