.main {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  min-height: 100vh;
  background-color: var(--secondary-bg-color);
}

@media (max-width: 768px) {
  .main {
    padding: 1rem;
  }
}
