.bookUpload {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.bookUpload__back {
  position: absolute;
  top: 40px;
  right: 40px;
}

.imagesContainer {
  margin-bottom: 1rem;
  display: flex;
  flex-direction: row;
  gap: 5rem;
  align-items: center;
}

.imageContainer {
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.uploadButtonContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.image {
  border-radius: var(--layout-border-radius);
  margin-bottom: 0.5rem;
}

.inputsContainer {
  width: 70%;
}

.inputGroup {
  margin-bottom: 1rem;
}

.bookItem {
  display: flex;
  flex-direction: column;
}

.error {
  color: red;
  font-size: 0.8rem;
}

.buttonContainer {
  margin-top: 1rem;
  display: flex;
  justify-content: center;
}

.removeButton {
  background: none;
  border: none;
  color: red;
  cursor: pointer;
  margin-top: 0.5rem;
}

.pdfPreview {
  margin-top: 1rem;
  width: 100%;
  display: flex;
  justify-content: center;
}

.pdfPreview canvas {
  width: 100% !important;
  height: auto !important;
  max-width: 100%;
}

.radioGroup {
  display: flex;
  gap: 20px;
  margin-top: 10px;
}

.radioItem {
  display: flex;
  align-items: center;
}

.radioItem input {
  margin-right: 8px;
}

.error {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}

.inputGroup {
  margin-bottom: 20px;
}

label {
  display: flex;
  flex-direction: column;
  font-weight: bold;
  margin-bottom: 5px;
}

.buttonContainer {
  text-align: center;
  margin-top: 30px;
}

.toggleGroup {
  display: flex;
  gap: 10px;
  margin-top: 5px;
  margin-bottom: 10px;
}

.fileUploadContainer {
  margin-top: 10px;
}

.error {
  color: red;
  font-size: 0.8rem;
  margin-top: 5px;
  display: block;
}
