.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  border-radius: 25px;
  background-color: var(--primary-bg-color);
  max-width: 600px;
  width: 100%;
}

@media (max-width: 768px) {
  .form {
    padding: 1rem;
  }
}
