:root {
  --max-width: 1100px;
  --border-radius: 12px;

  /* Colors */

  --primary-main: rgb(80, 65, 188);
  --primary-dark: rgb(13, 13, 13);
  --primary-white: rgb(255, 255, 255);

  --secondary-green: rgb(67, 190, 131);
  --secondary-orange: rgb(255, 128, 87);

  --primary-bg-color: rgb(255, 255, 255);
  --secondary-bg-color: rgb(222, 225, 231);
  --thrird-bg-color: rgb(245, 243, 255);

  --gradient: linear-gradient(
    to bottom right,
    rgb(61, 41, 208),
    rgb(194, 95, 255)
  );

  --layout-border-radius: 40px;

  /* --primary-glow: conic-gradient(
        from 180deg at 50% 50%,
        #16abff33 0deg,
        #0885ff33 55deg,
        #54d6ff33 120deg,
        #0071ff33 160deg,
        transparent 360deg
      ); */
  /* --secondary-glow: radial-gradient(
        rgba(255, 255, 255, 1),
        rgba(255, 255, 255, 0)
      ); */

  /* --tile-start-rgb: 239, 245, 249;
      --tile-end-rgb: 228, 232, 233;
      --tile-border: conic-gradient(
        #00000080,
        #00000040,
        #00000030,
        #00000020,
        #00000010,
        #00000010,
        #00000080
      );
    
      --callout-rgb: 238, 240, 241;
      --callout-border-rgb: 172, 175, 176;
      --card-rgb: 180, 185, 188;
      --card-border-rgb: 131, 134, 135; */
}
