.notFound {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  min-height: 100vh;
  background-color: var(--secondary-bg-color);
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  border-radius: 25px;
  background-color: var(--primary-bg-color);
  max-width: 600px;
  width: 100%;
}
