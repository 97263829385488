.bookDetails {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.bookImages {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  margin-bottom: 20px;
}
.audio_bgnd {
  text-align: center;
}

.bookImage {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.bookNotFound {
  color: var(--secondary-orange);
  font-size: 18px;
  text-align: center;
  margin-top: 20px;
}

.image {
  border-radius: var(--layout-border-radius);
}

.mainContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  margin-bottom: 20px;
}

.inputsContainer {
  width: 70%;
}

.inputGroup {
  margin-bottom: 1rem;
}

.bookItem {
  display: flex;
  flex-direction: column;
}

.buttonsContainer {
  display: flex;
  margin-bottom: 50px;
}

.pdfPreview {
  margin-top: 1rem;
  width: 100%;
  display: flex;
  justify-content: center;
}

.pdfPreview canvas {
  width: 100% !important;
  height: auto !important;
  max-width: 100%;
}

.pageTypeContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.pageTypeContent {
  display: flex;
  overflow-x: auto;
  gap: 10px;
  padding-bottom: 10px;
  flex-wrap: wrap;
}

.pageTypeContent > * {
  flex-shrink: 0;
}

.pageTypeContent::-webkit-scrollbar {
  height: 8px;
}

.pageTypeContent::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar-thumb-color);
  border-radius: 4px;
}

.pageTypeContent::-webkit-scrollbar-track {
  background-color: var(--scrollbar-track-color);
}

.toggleGroup {
  display: flex;
  gap: 10px;
  margin-top: 5px;
  margin-bottom: 10px;
}

.fileUploadContainer {
  margin-top: 10px;
}

.error {
  color: red;
  font-size: 0.8rem;
  margin-top: 5px;
  display: block;
}
