.container {
  width: 100%;
  margin-bottom: 15px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.input,
.select__control {
  padding: 1rem;
  border: 1px solid #ddd;
  border-radius: 25px;
  background-color: var(--third-bg-color);
  font-size: 16px;
}

.input::placeholder {
  color: #7a7a7a;
}

.errorContainer {
  height: 16px; /* Reserved space for the error message */
}

.error {
  color: red;
  font-size: 12px;
  margin: 0;
  padding: 0;
  height: 16px; /* Height of the error text */
}

.select__control--is-focused {
  border-color: #ddd;
  box-shadow: none;
}

.select__multi-value {
  background-color: var(--second-bg-color);
  color: #fff;
}

.select__multi-value__label {
  color: #fff;
}

.select__multi-value__remove {
  color: #fff;
}

@media (max-width: 768px) {
  .input,
  .select__control {
    max-width: 90%;
    padding: 16px;
    font-size: 14px;
  }
}
