.booksContainer {
  padding: 20px;
}

.searchContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.header {
  text-align: center;
  margin: 0;
  margin-bottom: 20px;
  font-size: 2rem;
  color: var(--primary-text-color);
}

.booksGrid {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

@media (max-width: 992px) {
  .booksContainer {
    padding: 15px;
  }

  .header {
    font-size: 1.8rem;
  }

  .booksGrid {
    gap: 15px;
  }
}

@media (max-width: 768px) {
  .booksContainer {
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .searchContainer {
    width: 70%;
  }

  .header {
    font-size: 1.5rem;
  }

  .booksGrid {
    flex-direction: column;
    align-items: center;
  }
}
