.imageContainer {
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.uploadButtonContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.image {
  border-radius: var(--layout-border-radius);
  margin-bottom: 0.5rem;
}

.removeButton {
  background: none;
  border: none;
  color: red;
  cursor: pointer;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
}
