.categoryCard {
  display: flex;
  flex-direction: column; /* Ensure flex-direction is column */
  border-radius: var(--layout-border-radius);
  padding: 10px 20px;
  background-color: var(--primary-bg-color);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 80%;
  position: relative;
  transition: all 0.3s ease-in-out;
}

.cardContent {
  display: flex;
  align-items: center;
}

.categoryCardDetails {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  flex-grow: 1;
}

.categoryCardName {
  font-weight: bold;
  margin-right: 20px;
  color: var(--primary-main);
}

.categoryCardDescription {
  color: var(--primary-dark);
  margin-right: 20px;
}

.categoryCardOptions {
  margin-left: auto;
  cursor: pointer;
  display: flex;
}

.editContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
  animation: fadeIn 0.5s ease-in-out;
  width: 100%;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(0.95);
  }
}

.fadeOut {
  animation: fadeOut 0.3s forwards;
}

.editing {
  padding-bottom: 20px;
}

.buttonsContainer {
  display: flex;
  justify-content: center;
}
