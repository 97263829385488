.dashboard {
  display: flex;
  height: 100vh;
  position: relative;
  justify-content: flex-end;
}

.mainContent {
  width: calc(100% - 350px);
  border-top-left-radius: var(--layout-border-radius);
  border-bottom-left-radius: var(--layout-border-radius);
  background-color: var(--primary-bg-color);
  padding: 20px;
}

.noPadding {
  width: calc(100% - 310px);
  padding: 0;
}

@media (max-width: 1200px) {
  .mainContent {
    width: calc(100% - 0px);
  }
}

@media (max-width: 992px) {
  .mainContent {
    width: calc(100% - 270px);
  }
}

@media (max-width: 768px) {
  .mainContent {
    width: 100%;
    padding: 10px;
  }
}
