.button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: none;
  color: var(--primary-white);
  cursor: pointer;
  background-color: var(--secondary-green);
  margin-right: 1rem;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.buttonSecondary {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
  border: none;
  color: var(--primary-white);
  background-color: var(--secondary-orange);
  transition: background-color 0.3s ease, color 0.3s ease;
}

.button:hover {
  background-color: var(--primary-white);
  color: var(--secondary-green);
}

.buttonSecondary:hover {
  background-color: var(--primary-white);
  color: var(--secondary-orange);
}
