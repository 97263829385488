.container {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  display: flex;
  width: 250px;
  background-color: var(--primary-main);
  border-top-left-radius: var(--layout-border-radius);
  border-bottom-left-radius: var(--layout-border-radius);
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 2rem;
  transition: transform 0.3s ease;
  transform: translateX(0);
  z-index: 100;
}

.imageContainer {
  display: flex;
  justify-content: center;
}

.image {
  width: 200px;
  height: 200px;
  border-radius: var(--layout-border-radius);
}

.menuIcon {
  display: none;
  position: fixed;
  top: 1rem;
  left: 1rem;
  z-index: 1000;
  cursor: pointer;
  background-color: var(--primary-main);
  border-radius: var(--layout-border-radius);
  padding: 0.5rem;
}

@media (max-width: 1200px) {
  .container {
    width: 200px;
  }

  .image {
    width: 150px;
    height: 150px;
  }
}

@media (max-width: 992px) {
  .container {
    width: 180px;
  }

  .image {
    width: 130px;
    height: 130px;
  }
}

@media (max-width: 768px) {
  .container {
    width: 250px;
    transform: translateX(-100%);
  }

  .container.open {
    transform: translateX(0);
  }

  .menuIcon {
    display: flex;
  }

  .image {
    width: 150px;
    height: 150px;
  }

  .buttonsContainer {
    margin-top: 1rem;
  }
}
